import api from "../axios_service.js";

const fetchTransferenciasEstoque = async (filtros) => {
  const response = await api.get(
    `/transferencia-estoque${filtros ? filtros : ""}`
  );
  return response.data.result;
};

const fetchTransferenciaEstoque = async (id) => {
  const response = await api.get(`/transferencia-estoque/${id}`);
  return response.data.result;
};

const confirmarTransferenciaEstoque = async (id, body) => {
  return api.put(`/confirmar-transferencia-estoque/${id}`, body);
};

const postTransferenciaEstoque = async (body) => {
  return api.post("/transferencia-estoque/add", body);
};

const salvarTransferenciaEstoque = async (id, body) => {
  return api.post(`/salvar-transferencia-estoque/${id}`, body);
};

const cancelarTransferenciaEstoque = async (id) => {
  return api.put(`/cancelar-transferencia-estoque/${id}`);
};

export {
  fetchTransferenciasEstoque,
  fetchTransferenciaEstoque,
  confirmarTransferenciaEstoque,
  postTransferenciaEstoque,
  salvarTransferenciaEstoque,
  cancelarTransferenciaEstoque,
};
